export default (id, onOpen = false) => {

    const modal = document.querySelector('[data-modal="' + id + '"]');
    const openBtns = document.querySelectorAll('[data-open-modal="' + id + '"]');
    const closeBtns = document.querySelectorAll('[data-close-modal="' + id + '"]');
    const openClass = 'modal-open';

    if (! modal) return false;

    for (let i = 0; i < openBtns.length; i++) openBtns[i].addEventListener('click', () => {

        if (! modal.classList.contains(openClass)) modal.classList.add(openClass);

        if (onOpen) onOpen();

    });


    for (let i = 0; i < closeBtns.length; i++) closeBtns[i].addEventListener('click', () => {

        if (modal.classList.contains(openClass)) modal.classList.remove(openClass);

    });

    return modal;

};
