import Axios from "axios";
import modal from "./../modal.js";
import FormBase from "./Form";
import Noty from "noty";

const displayError = (form, msg) => {
    form.classList.add("error");

    const error = document.createElement("div");
    error.classList.add("landing-hero-contact-form__error");
    error.innerHTML = msg;

    form.innerHTML = "";
    form.appendChild(error);
};

const showModal = (entryId) => {
    const modalElem = modal("landing-hero-form-modal");
    const form = modalElem.querySelector("[data-landing-hero-modal-form]");
    // const hiddenInput = modalElem.querySelector('[data-entry-id-input]');
    const formEndpoint = "/api/v1/visitor-forms/submit";

    modalElem.classList.add("modal-open");

    form.addEventListener("submit", function (e) {
        e.preventDefault();

        let emailInput = form.querySelector('input[name="email"]');
        let callbackSelect = form.querySelector(
            'select[name="preferred_callback_time"]'
        );
        let messageInput = form.querySelector('textarea[name="message"]');
        let visitorSessionId = FormBase.getVisitorSessionId(form);

        if (
            !emailInput.value ||
            !messageInput.value ||
            !callbackSelect.value ||
            !visitorSessionId
        )
            return;

        let formData = {
            email: emailInput.value,
            preferred_callback_time: callbackSelect.value,
            message: messageInput.value,
            visitor_session_id: visitorSessionId,
        };

        Axios.post(formEndpoint, {
            form_key: "request-callback-continue",
            existing_submission_id: entryId,
            ...formData,
        })
            .then((res) => {
                if (res.status !== 200) {
                    console.log(res);
                    displayError(
                        form,
                        "There was an error submitting your request."
                    );
                } else {
                    if (typeof gtag_report_conversion !== "undefined") {
                        gtag_report_conversion?.();
                    }

                    FormBase.triggerGTMEvent("twoStepRequestCallback2");

                    showModalFormSuccess(modalElem, form);
                }
            })
            .catch((e) => {
                console.log(e);
                displayError(
                    form,
                    "There was an error submitting your request."
                );
            });
    });
};

const showFormSuccess = (form) => {
    form.classList.add("success");

    const success = document.createElement("div");
    success.classList.add("landing-hero-contact-form__success");
    success.innerHTML = "Your request has been sent successfully.";
    // Add white check mark image
    form.innerHTML = "";
    form.appendChild(success);
};

const showModalFormSuccess = (modal, form) => {
    modal.classList.add("success");

    modal.querySelector(".landing-hero-form-modal__success-heading").innerHTML =
        "Thank you for providing more information. A member of our team will be in touch shortly.";
    modal.querySelector(".landing-hero-form-modal__success-copy").innerHTML =
        "";

    const button = document.createElement("a");
    button.href = "personal/family-law-solicitors/";
    button.classList.add("landing-hero-form-modal__success-btn");
    button.classList.add("btn-corner");
    button.classList.add("btn-corner--filled-brand");
    button.innerHTML = "Find out more about our Family Law services";
    modal
        .querySelector(".landing-hero-form-modal__success-text")
        .appendChild(button);

    form.classList.add("hidden");
};

const formatNameInput = (name = null) => {
    // Throw out if name not given
    if (!name) {
        return null;
    }

    // Break name down into parts
    let parts = name?.trim()?.split(" ");

    // Extract name values
    let first_name = parts?.shift() ?? null;
    let last_name = parts?.pop() ?? null;
    let middle_names = parts?.length ? parts?.join(" ") : null;

    // Return array of broken down name
    return {
        firstName: first_name,
        middleNames: middle_names,
        lastName: last_name,
    };
};

export default () => {
    const form = document.querySelector("[data-landing-hero-form]");
    const formEndpoint = "/api/v1/visitor-forms/submit";

    if (!form) return false;

    form.addEventListener("submit", function (e) {
        e.preventDefault();

        let nameInput = form.querySelector('input[name="name"]');
        let telephoneInput = form.querySelector('input[name="telephone"]');
        let departmentInput = form.querySelector('select[name="department"]');
        let visitorSessionId = FormBase.getVisitorSessionId(form);

        if (
            !nameInput.value ||
            !telephoneInput.value ||
            !departmentInput.value ||
            !visitorSessionId
        )
            return;

        let formattedName = formatNameInput(nameInput?.value);
        if (!formattedName?.firstName || !formattedName?.lastName) {
            return new Noty({
                text: "Please enter your full name",
                type: "error",
                progressBar: true,
                theme: "semanticui",
                timeout: 4000,
                layout: "topRight",
            }).show();
        }

        Axios.post(formEndpoint, {
            form_key: "request-callback-simple",
            visitor_session_id: visitorSessionId,
            first_name: formattedName?.firstName,
            last_name: formattedName?.lastName,
            telephone: telephoneInput.value,
            department: departmentInput.value,
        })
            .then((res) => {
                if (res.status !== 200) {
                    console.log(res);
                    displayError(
                        form,
                        "There was an error submitting your request."
                    );
                } else {
                    if (typeof gtag_report_conversion !== "undefined") {
                        gtag_report_conversion?.();
                    }

                    let entryId = res.data.body.submission_id;

                    FormBase.triggerGTMEvent(
                        "twoStepRequestCallback1",
                        departmentInput.value
                    );

                    showFormSuccess(form);

                    showModal(entryId);
                }
            })
            .catch((e) => {
                console.log(e);
                displayError(
                    form,
                    "There was an error submitting your request."
                );
            });

        // Do axios that starts timer on server side
        // Get form session ID
        // Show modal
        // On submit of modal, send data and session ID, cancel session timer
    });
};

// export default () => {

//     const endpoint = '/api/get-google-reviews';

//     let reviews = false;

//     if (window.google_reviews_loaded) return false;

//     Axios.get(endpoint)
//     .then((res) => {

//         if (res.data.status !== 200) {

//             displayError('Google API Error: Unable to load reviews.');

//         } else {

//             if (res.data.body.length) {

//                 displayReviews(res.data.body);
//                 window.google_reviews_loaded = true;

//             } else {

//                 displayError('No reviews to display.');

//             }

//         }

//     })
//     .catch((e) => {

//         displayError('Unable to load reviews.');

//     });

// }
