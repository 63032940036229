const core = () => {

    const toggles = document.querySelectorAll('[data-toggle-mobile-menu]');
    const menu = document.querySelector('[data-mobile-menu]');
    const openClass = 'mobile-menu--open';

    if (! menu) return false;

    for (let i = 0; i < toggles.length; i++) {

        toggles[i].addEventListener('click', (e) => {

            if (menu.classList.contains(openClass)) {
                menu.classList.remove(openClass);
            } else {
                menu.classList.add(openClass);
            }

        });

    }

};


const hoverContent = (toggleSelector, contentClass) => {

    const initiator = document.querySelector(toggleSelector);
    const menu = document.querySelector('.mobile-menu');
    const menuItems = menu.querySelectorAll('.header-main-menu__item');

    const displayContent = () => {

        menu.classList.add(contentClass);

    };

    const hideContent = () => {

        menu.classList.remove(contentClass);

    };

    if (! menu || ! initiator) return false;

    for (let i = 0; i < menuItems.length; i++) menuItems[i].addEventListener('mouseenter', () => {

        hideContent();

    });

    initiator.addEventListener('mouseenter', () => {

        displayContent();

    });

};

export default () => {

    core();

    // hoverContent('.mobile-menu .toggle-mobile-menu-business-content', 'mobile-menu--related-services-business-visible');
    // hoverContent('.mobile-menu .toggle-mobile-menu-personal-content', 'mobile-menu--related-services-personal-visible');
    // hoverContent('.mobile-menu .toggle-mobile-menu-team-content', 'mobile-menu--related-services-team-visible');
    // hoverContent('.mobile-menu .toggle-mobile-menu-blog-content', 'mobile-menu--related-services-blog-visible');
    // hoverContent('.mobile-menu .toggle-mobile-menu-contact-content', 'mobile-menu--related-services-contact-visible');

}
