import Noty from 'noty';

export default class Flash
{

    start() {

        const elems = document.querySelectorAll('.notification');

        for (let i = 0; i < elems.length; i++) {

            new Noty({
                text: elems[i].getAttribute('data-notification-message'),
                type: elems[i].getAttribute('data-notification-type') || 'info',
                progressBar: true,
                theme: 'semanticui',
                timeout: 4000,
                layout: 'topRight',
            }).show();

        }

    }

}
