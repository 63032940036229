import Axios from "axios";
import modal from "./../modal.js";
import FormBase from "./Form";
import Noty from "noty";

const displayError = (form, msg) => {
    form.classList.add("error");

    const error = document.createElement("div");
    error.classList.add("services-request-callback-form__error");
    error.innerHTML = msg;

    form.innerHTML = "";
    form.appendChild(error);
};

const showFormSuccess = (form) => {
    form.classList.add("services-request-callback-form--success");
};

const formatNameInput = (name = null) => {
    // Throw out if name not given
    if (!name) {
        return null;
    }

    // Break name down into parts
    let parts = name?.trim()?.split(" ");

    // Extract name values
    let first_name = parts?.shift() ?? null;
    let last_name = parts?.pop() ?? null;
    let middle_names = parts?.length ? parts?.join(" ") : null;

    // Return array of broken down name
    return {
        firstName: first_name,
        middleNames: middle_names,
        lastName: last_name,
    };
};

export default () => {
    const container = document.querySelector(
        "[data-services-request-callback-container]"
    );
    const form = document.querySelector(
        "[data-services-request-callback-form]"
    );
    const formEndpoint = "/api/v1/visitor-forms/submit";

    if (!form || !container) return false;

    form.addEventListener("submit", function (e) {
        e.preventDefault();

        let fields = {
            name: form.querySelector('input[name="name"]'),
            preferred_callback_time: form.querySelector(
                'select[name="preferred_callback_time"]'
            ),
            email: form.querySelector('input[name="email"]'),
            telephone: form.querySelector('input[name="telephone"]'),
            message: form.querySelector('textarea[name="message"]'),
        };

        for (let i in fields) {
            if (!fields[i].value) return console.log(fields[i]);
        }

        let formattedName = formatNameInput(fields?.name?.value);
        if (!formattedName.firstName || !formattedName?.lastName) {
            return new Noty({
                text: "Please enter your full name",
                type: "error",
                progressBar: true,
                theme: "semanticui",
                timeout: 4000,
                layout: "topRight",
            }).show();
        }

        let visitorSessionId = FormBase.getVisitorSessionId(form);

        let formData = {
            first_name: formattedName?.firstName,
            last_name: formattedName?.lastName,
            preferred_callback_time: fields.preferred_callback_time.value,
            email: fields.email.value,
            telephone: fields.telephone.value,
            message: fields.message.value,
            visitor_session_id: visitorSessionId,
            // department: fields.department.value,
        };

        Axios.post(formEndpoint, {
            form_key: "request-callback-services",
            ...formData,
        })
            .then((res) => {
                if (res.status !== 200) {
                    console.log(res);
                    displayError(
                        form,
                        "There was an error submitting your request."
                    );
                } else {
                    if (typeof gtag_report_conversion !== "undefined") {
                        gtag_report_conversion?.();
                    }

                    let entryId = res.data.body.submission_id;

                    FormBase.triggerGTMEvent("requestCallback");
                    showFormSuccess(container);
                }
            })
            .catch((e) => {
                console.log(e);
                displayError(
                    form,
                    "There was an error submitting your request. Axios error."
                );
            });
    });
};
