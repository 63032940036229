import Swiper from 'swiper';

export default () => {

    const elems = document.querySelectorAll('[data-locations-slider]');

    for (let i = 0; i < elems.length; i++) {

        let elem = elems[i];
        let sliderId = elem.getAttribute('data-locations-slider');
        let pagination = document.querySelector('[data-locations-slider-pagination="' + sliderId + '"]');

        let slider = new Swiper(elem, {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 20,
            observer: false,
            observeParents: false,
            pagination: {
                el: pagination,
                clickable: true,
            },
            // breakpoints: {
            //     750: {
            //         slidesPerView: 2,
            //     },
            // },
        });


        const detachEvents = (slider) => {

            let windowWidth = window.innerWidth;

            if (windowWidth >= 750) {
                slider.detachEvents();
            } else {
                // slider.attachEvents();
            }

        };

        detachEvents(slider);

        window.addEventListener('resize', () => {

            detachEvents(slider);

        });

    }

};
