export default () => {

    let jsonData = window.AticusDataJson;

    if (! jsonData) {

        window.AticusData = {};

    } else {

        window.AticusData = JSON.parse(jsonData);

    }

}
