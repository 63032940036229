import Bowser from 'bowser';

export default () => {

    let mapsLink = false;
    let inNewTab = false;

    const googleMapsLink = 'https://maps.google.com/?q=';
    const appleMapsLink = 'http://maps.apple.com/?q=';

    const userAgent = window.navigator.userAgent;
    const browser = Bowser.getParser(userAgent);

    const platform = browser.getPlatform();

    let isApple = false;

    if (platform) {

        if (platform.vendor === 'Apple') isApple = true;

    }

    if (isApple) {
        mapsLink = appleMapsLink;
    } else {
        mapsLink = googleMapsLink;
        inNewTab = true;
    }


    const links = document.querySelectorAll('[data-maps-link]');

    for (let i = 0; i < links.length; i++) {

        const address = links[i].getAttribute('data-maps-link');
        const href = mapsLink + address;

        links[i].href = href;

        if (inNewTab) links[i].target = '_blank';

    }


};
