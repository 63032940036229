export default () => {

    const selects = document.querySelectorAll('[data-select-redirect]');

    for (let i = 0; i < selects.length; i++) selects[i].addEventListener('change', () => {

        window.location.replace(selects[i].value);

    });

}
