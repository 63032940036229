import Swiper from 'swiper';

export default () => {

    // console.log('News Swiper Loaded!');

    const elems = document.querySelectorAll('[data-news-slider]');

    for (let i = 0; i < elems.length; i++) {

        let elem = elems[i];
        let sliderId = elem.getAttribute('data-slider-id');
        let navigationPrev = document.querySelector('[data-news-slider-nav-prev="' + sliderId + '"]');
        let navigationNext = document.querySelector('[data-news-slider-nav-next="' + sliderId + '"]');

        let slider = new Swiper(elems[i], {
            slidesPerView: 1,
            spaceBetween: 30,
            observer: true,
            observeParents: true,
            breakpoints: {
                992: {
                    slidesPerView: 2,
                },
            },
        });


        navigationPrev.addEventListener('click', () => slider.slidePrev());
        navigationNext.addEventListener('click', () => slider.slideNext());

    }

};
