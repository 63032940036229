export default () => {

    const setSticky = () => {

        const header = document.querySelector('.site-header');
        const windowWidth = window.innerWidth;
        const scrollPos = window.scrollY;
        let fixPosition;

        if (windowWidth > 992) {
            fixPosition = 121;
        } else if (windowWidth <= 390) {
            fixPosition = 63;
        } else if (windowWidth <= 650) {
            fixPosition = 72;
        } else if (windowWidth <= 992) {
            fixPosition = 23;
        }

        if (scrollPos >= fixPosition) {
            header.classList.add('site-header--fixed');
        } else {
            header.classList.remove('site-header--fixed');
        }

    };


    window.addEventListener('scroll', setSticky);
    window.addEventListener('resize', setSticky);

};
