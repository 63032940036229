export default () => {

    const key = 'data-delay-pointer-events';

    window.addEventListener('load', () => {

        const elems = document.querySelectorAll('['+key+']');

        let elem;
        let delayInSeconds;
        for (let i = 0; i < elems.length; i++) {

            elem = elems[i];
            delayInSeconds = elem.getAttribute(key);

            setTimeout(()=> {
                elem.classList.add('pointer-events-active');
            }, delayInSeconds);

        }

    });

};
