import newsSlider from './includes/sliders/news-slider.js';
import teamSlider from './includes/sliders/team-slider.js';
import locationsSlider from './includes/sliders/locations-slider.js';
import selectRedirect from './includes/select-redirect.js';
import mobileMenu from './includes/mobile-menu.js';
import modal from './includes/modal.js';
import googleReviews from './includes/google-reviews.js';
// import fileUploader from './../../../claim-processes/assets/file-uploader.js';
import heroRequestCallbackForm from './includes/forms/hero-request-callback.js';
import servicesRequestCallbackForm from './includes/forms/services-request-callback.js';
import requestCallbackForm from './includes/forms/request-callback.js';
import stickyHeader from './includes/sticky-header.js';
import Flash from './includes/Flash';
import delayPointerEvents from './includes/delay-pointer-events';
import geoMapsLink from './includes/geo-map-links';
import whenCookiesConsented from './includes/cookies-consented';
import loadRtap from './includes/load-rtap';
import loadPhpDataObject from './includes/load-php-data-object';
import openingHoursTimer from './includes/opening-hours-timer';
import loadUserAgentData from './includes/load-user-agent-data';

document.addEventListener('DOMContentLoaded', () => {

    loadPhpDataObject();

    loadUserAgentData();

    whenCookiesConsented(() => {
        console.log('Cookies Consented 🍪');
    });

    openingHoursTimer();

    stickyHeader();

    delayPointerEvents();

    mobileMenu();

    let notification = new Flash().start();

    newsSlider();

    teamSlider();

    locationsSlider();

    selectRedirect();

    modal('request-callback-modal');

    requestCallbackForm();

    modal('content-download-area-register');

    modal('google-reviews-modal', googleReviews);

    heroRequestCallbackForm();

    servicesRequestCallbackForm();

    geoMapsLink();

    // fileUploader();
});
