import Axios from 'axios';

// let exampleGoogleReviewObject = {
//     author_name: "Elle Goddard",
//     author_url: "https://www.google.com/maps/contrib/101040317882514879313/reviews",
//     language: "en",
//     profile_photo_url: "https://lh3.googleusercontent.com/a/AATXAJy_keuMBMe6Rg-y4-1Chp638pmzd3qzJpgHVWXd=s128-c0x00000000-cc-rp-mo",
//     rating: 5,
//     relative_time_description: "2 months ago",
//     text: "We recently instructed Milena and the team at Aticus Law (Wilmslow) to assist us with our house sale and purchase and I cannot recommend her highly enough. Incredibly thorough and professional throughout and pushed through the transaction in record time for us!\n\nIt was refreshing to be kept up to date on the conveyancing process regularly without having to ask and nothing ever seemed too much trouble. We were very happy with the service we received and Milena was an absolute pleasure to deal with.",
//     time: 1612873665,
// };

const displayReviews = (reviews) => {

    const container = document.querySelector('[data-google-reviews-container]');
    const elems = [];

    for (let i = 0; i < reviews.length; i++) {

        const name = document.createElement('p');
        name.classList.add('review-card__name');
        name.innerHTML = reviews[i].author_name;

        const quote = document.createElement('blockquote');
        quote.classList.add('review-card__quote');
        quote.innerHTML = reviews[i].text;

        const rating = document.createElement('div');
        rating.classList.add('review-card__rating');

        for (let j = 1; j <= reviews[i].rating; j++) {

            let star = document.createElement('img');
            star.src = window.location.origin + '/images/ui/star.svg';
            star.alt = 'Star rating Icon';

            rating.appendChild(star);

        }

        const inner = document.createElement('div');
        inner.classList.add('review-card__inner');
        inner.appendChild(name);
        inner.appendChild(quote);
        inner.appendChild(rating);

        const review = document.createElement('div');
        review.classList.add('review-card');
        review.appendChild(inner);

        const outer = document.createElement('div');
        outer.classList.add('google-reviews-modal__review');
        outer.appendChild(review);

        elems.push(outer);

    }

    container.innerHTML = '';

    for (let i = 0; i < elems.length; i++) {

        container.appendChild(elems[i]);

    }


};

const displayError = (msg) => {

    const container = document.querySelector('[data-google-reviews-container]');

    const error = document.createElement('div');
    error.classList.add('google-reviews-modal__error');
    error.innerHTML = msg;

    container.innerHTML = '';
    container.appendChild(error);

};


export default () => {

    const endpoint = '/api/v1/get-google-reviews';

    let reviews = false;

    if (window.google_reviews_loaded) return false;

    Axios.get(endpoint)
    .then((res) => {

        if (res.status !== 200) {

            displayError('Google API Error: Unable to load reviews.');

        } else {

            if (res.data.body.reviews.length) {

                displayReviews(res.data.body.reviews);
                window.google_reviews_loaded = true;

            } else {

                displayError('No reviews to display.');

            }

        }

    })
    .catch((e) => {

        displayError('Unable to load reviews.');

    });

}
