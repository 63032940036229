import Bowser from 'bowser';

export default () => {

    const body = document.querySelector('body');
    const userAgent = window.navigator.userAgent;
    const browser = Bowser.getParser(userAgent);

    const platform = browser.getPlatform();
    const platformType = browser.getPlatformType();
    const osName = browser.getOSName();
    const browserName = browser.getBrowserName();
    const browserEngine = browser.getEngineName();

    body.setAttribute('data-useragent-platform-vendor', platform.vendor);
    body.setAttribute('data-useragent-platform-type', platformType);
    body.setAttribute('data-useragent-os-name', osName);
    body.setAttribute('data-useragent-browser-name', browserName);
    body.setAttribute('data-useragent-browser-engine', browserEngine);

}
