class CookieConsent
{

    constructor() {

        const configElem = document.querySelector('[data-cookie-consent-config]');

        if (! configElem) return false;

        const configData = JSON.parse(configElem.getAttribute('data-cookie-consent-config'));

        this.cookieConsentConfig = configData.cookie_consent_config;
        this.cookieDomain = configData.cookie_domain;
        this.secureSession = configData.session_secure;
        this.sameSiteSession = configData.session_same_site;
        this.cookieName = this.cookieConsentConfig.cookie_name;
        this.cookieLifetime = this.cookieConsentConfig.cookie_lifetime;

    }


    consentWithCookies() {

        this.setCookie(1);
        // Trigger event here
        this.hideCookieDialog();
    }


    declineWithCookies() {
        this.setCookie(0);
        this.hideCookieDialog();
    }


    showCookieDialog() {

        const dialogs = document.querySelectorAll('.cookie-notice');

        for (let i = 0; i < dialogs.length; i++) {
            dialogs[i].classList.add('cookie-notice--open');
        }

    }


    hideCookieDialog() {

        const dialogs = document.querySelectorAll('.cookie-notice');

        for (let i = 0; i < dialogs.length; i++) {
            dialogs[i].classList.remove('cookie-notice--open');
        }

    }


    cookieExists() {
        return (document.cookie.split('; ').indexOf(this.cookieName + '=' + 1) !== -1);
    }


    setCookie(value) {

        const date = new Date();
        date.setTime(date.getTime() + (this.cookieLifetime * 24 * 60 * 60 * 1000));

        const cookie = this.cookieName + '=' + value
            + ';expires=' + date.toUTCString()
            + ';domain=' + this.cookieDomain
            + ';path=/' + (this.secureSession ? ';secure' : '')
            + (this.sameSiteSession ? ';samesite='+this.sameSiteSession : '');

        console.log(cookie);

        document.cookie = cookie;
    }


    registerEventListeners(callback) {

        const buttons = document.querySelectorAll('.js-cookie-consent-agree');
        const declineButtons = document.querySelectorAll('.js-cookie-consent-decline');

        for (let i = 0; i < buttons.length; ++i) {
            buttons[i].addEventListener('click', () => {

                this.consentWithCookies();
                this.fireConsentEvent();

            });
        }

        for (let i = 0; i < declineButtons.length; ++i) {
            declineButtons[i].addEventListener('click', () => {
                this.declineWithCookies()
            });
        }


        window.addEventListener('cookies-consented', () => callback());

    }


    fireConsentEvent() {

        const cookiesConsentedEvent = new Event('cookies-consented', {bubbles: true});

        window.dispatchEvent(cookiesConsentedEvent);

    }


}


export default (callback) => {

    const cookieConsent = new CookieConsent();

    cookieConsent.registerEventListeners(callback);

    if (! cookieConsent.cookieExists()) {

        window.addEventListener('load', () => {

            setTimeout(() => {
                cookieConsent.showCookieDialog();
            }, 500);

        });

    } else {
        cookieConsent.hideCookieDialog();
        cookieConsent.fireConsentEvent();
    }

};
