export default {

    getVisitorSessionId: (form) => {

        let input = form.querySelector('input[name="visitor_session_id"]');

        if (! input) return false;

        return input.value;

    },

    getUtmParams: (form) => {

        let obj = {};

        let utmSource = form.querySelector('input[name="utm_source"]');
        let utmMedium = form.querySelector('input[name="utm_medium"]');
        let utmCampaign = form.querySelector('input[name="utm_campaign"]');
        let utmTerm = form.querySelector('input[name="utm_term"]');
        let utmContent = form.querySelector('input[name="utm_content"]');

        if (utmSource) obj.utm_source = utmSource.value;
        if (utmMedium) obj.utm_medium = utmMedium.value;
        if (utmCampaign) obj.utm_campaign = utmCampaign.value;
        if (utmTerm) obj.utm_term = utmTerm.value;
        if (utmContent) obj.utm_content = utmContent.value;

        return obj;

    },


    triggerGTMEvent: (formName, department = '') => {

        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            'event': 'webFormSubmitted',
            'formName': formName,
            'department': department,
        });

    }

};
