import Axios from "axios";

export default () => {

    const body = document.querySelector('body');
    const statusCheckInterval = 300000; // 5 mins
    const host = window.AticusData.host;
    const endpoint = host + '/api/v1/check-if-business-is-open';

    let isOpen = window.AticusData.business_is_open;

    const setOpeningStatus = (isOpen) => {

        body.setAttribute('data-business-open', isOpen);

    };

    setOpeningStatus(isOpen);

    setInterval(() => {

        let request = Axios.get(endpoint).then((res) => {

            if (res.status !== 200) return console.warn('API Error');

            setOpeningStatus(res.data.body.is_open);

        });

    }, statusCheckInterval);



}
